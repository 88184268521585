import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const TelSign = () => {
  //const countryCode = '+82';
  const [phoneNumber, setPhoneNumber] = useState('');
  const [expandForm, setExpandForm ] = useState(false);
  const [otpNumber, setOtpNumber] = useState('');

  const navigate = useNavigate();

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      }
    }, auth);

  }
  
  const requestOTP = (e) => {
    e.preventDefault();
    if(phoneNumber.length >=8){
      const krphoneNumber = '+8210' + phoneNumber;
      setExpandForm(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, krphoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
        }).catch((error) => {
          // Error; SMS not sent
          // ...
          console.log(error);
        });
    }
  }

  const verifyOTP = (e) => {
    let otp = e;
    setOtpNumber(otp);
    if(otp.length === 6){
      console.log(otp);
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        //const user = result.user;
        console.log('Hi Tel Login');
        navigate('/account');
        // ...
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
      });
    navigate('/account');
    }
  }

  return (
    <div className='max-w-[400px] mx-auto min-h-[600px] px-4 py-20'>
      <form onSubmit={requestOTP} >
        <h1 className='text-2xl font-bold'>Sign in with phone number</h1>
        <div className='my-4'>
          <label htmlFor='phoneNumberInput'>전화번호를 입력하세요</label>
          <div className='flex my-2 w-full relative rounded-2xl shadow-xl'>
            <p className='my-2 mx-2'>010 - </p>
            <input 
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type='tel' 
              maxLength={8}
              className='w-50 p-2 bg-primary border border-input rounded-2xl' 
              id='phoneNumberInput' 
            />
          </div>
          <p> </p>
        </div>
        {expandForm === true?
          <>
            <div className='my-4 w-full relative rounded-2xl shadow-xl'>
              <label >OTP</label>
              <input 
                value={otpNumber}
                onChange={(e) => verifyOTP(e.target.value)}
                type='number' 
                className='w-50 p-2 bg-primary border border-input rounded-2xl' 
                id='otpInput' 
              />
              <div id='otpHelp' >Please enter pin number</div>
            </div>
          </>
          :
          null
        }
        {expandForm === false?
          <button type="submit" 
            className='
            mx-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md 
            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
           '
          >
            인증번호 요청
          </button>
          :
          null
        }
        <div id="recaptcha-container"></div>
      </form>

    </div>
  )
}

export default TelSign