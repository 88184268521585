import React from 'react'

const Footer = () => {
  return (
    <div className='
      border rounded-2xl shadow-xl px-2 mt-4
    '>
      <p className='text-center py4'> Powered by EzYang </p>
    </div>
  )
}

export default Footer