import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { UserAuth } from '../context/AuthContext';

import {
  collection,
  onSnapshot
} from "firebase/firestore";
import { db } from "../firebase";

const Rooms = () => {
  const { user } = UserAuth();
  const [data, setData] = useState([]);

  useEffect( () => {
    const unsub = onSnapshot(
      collection(db, 'rooms'),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id:doc.id, ...doc.data() });
        })
        setData(list);
      },
      (error) => {
        console.log(error)
      }
    );
    return () => {
      unsub();
    }
  }, []);

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-3 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className='flex justify-between font-bold py-3'>
          <h2 className="text-2xl">Rooms</h2>
          {user?.email ? (
            <Link to="/rooms/new"  className='bg-button text-btnText px-5 h-10 py-2 ml-2 rounded-2xl shadow-lg hover:shadow-2xl '>
              Add New
            </Link>
          ) : (
            <></>
          )
          }
        </div>

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {data.map((room) => (
            <a key={room.id} href={room.href} className="group">
              <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                <img
                  src={room.img}
                  alt={room.imageAlt}
                  className="w-full h-full object-center object-cover group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-1 text-lg font-medium text-gray-900">{room.title}</h3>
              <p className="mt-4 text-sm text-gray-700">{room.description}</p>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Rooms