import React, { useState } from 'react';
import { AiFillLock, AiOutlineMail } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth, } from '../context/AuthContext';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, googleSignIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      navigate('/account');
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const handleGoogleLogin = async () => {
    console.log('Google SignIn')
    try {
      await googleSignIn();
      navigate('/account');
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <div className='max-w-[400px] mx-auto px-4 py-20'>
        <h1 className='text-2xl font-bold'>Sign In</h1>
        {error ? <p className='bg-red-300 p-3 my-2'>{error}</p> : null}
        <form onSubmit={handleSubmit}>
          <div className='my-4'>
            <label>Email</label>
            <div className='my-2 w-full relative rounded-2xl shadow-xl'>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className='w-full p-2 bg-primary border border-input rounded-2xl'
                type='email'
              />
              <AiOutlineMail className='absolute right-2 top-3 text-gray-400' />
            </div>
          </div>
          <div className='my-4'>
            <label>Password</label>
            <div className='my-2 w-full relative rounded-2xl shadow-xl'>
              <input
                onChange={(e) => setPassword(e.target.value)}
                className='w-full p-2 bg-primary border border-input rounded-2xl'
                type='password'
              />
              <AiFillLock className='absolute right-2 top-3 text-gray-400' />
            </div>
          </div>
          <button 
          className='
            w-full
            mx-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md 
            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          '
          >
            Sign in
          </button>
        </form>
      </div>

      <>
        <div className='max-w-[400px] mx-auto min-h-[600px] px-4 py-2'>
          <button onClick={handleGoogleLogin} 
          className='
            w-full
            mx-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md 
            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          '
          >
            Google Sign In
          </button>
          <>
            <div className='w-full py-4'>
              <Link to='/telsignin' 
              className='
              mx-2 py-2 px-4 boder text-sm font-medium rounded-md
              text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              '
              >
                TelSignIn
              </Link>              
            </div>
          </>

        </div>
      </>    



    </div>
  );
};

export default Signin;
