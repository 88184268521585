import React from "react";
import { Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import { AuthContextProvider } from './context/AuthContext';

import Home from './pages/Home';
import TelSignin from './pages/TelSignin';
import Account from './pages/Account';
import Signin from './pages/Signin';
import Rooms from "./pages/Rooms";
import NewRoom from './pages/NewRoom';
import Shop from "./pages/Shop";
import NewProduct from "./pages/NewProduct";

function App() {
  return (
    <AuthContextProvider>
      <Navbar />
      
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/rooms'>
            <Route index element={<Rooms/>} />
            <Route path="new" element={<NewRoom/>} />
          </Route>
          <Route path='/shop'>
            <Route index element={<Shop />} />
            <Route path="new" element={<NewProduct/>} />
          </Route>
          <Route path='/account' element={<Account />} />
          <Route path='/telsignin' element={<TelSignin />} />
          <Route path='/signin' element={<Signin />} />          
        </Routes>

      <Footer />
    </AuthContextProvider>
  );
}

export default App;
