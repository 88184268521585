import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { UserAuth } from '../context/AuthContext';


const Navbar = () => {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className='
      flex itmes-center p-2 justify-between h-15 
      border rounded-2xl shadow-xl
      bg-gray-100 font-bold
      hover:text-gray-700
    '>
      <Link to='/'>
        <h1 className='text-2xl'>YJStudy</h1>
      </Link>

      <Link to='/rooms' className='p-2 hover:text-accent'>
        Rooms
      </Link>
      <Link to='/shop' className='p-2 hover:text-accent'>
        Shop
      </Link>

      {user ? (
        <div>
          <Link to='/account' className='p-4 hover:text-accent'>
            Account
          </Link>
          <button 
            onClick={handleSignOut}
            className='
            mx-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md 
            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          '
          >Sign out</button>
        </div>
      ) : (
        <div className='py-2'>

          {/* <Link to='/signin' 
          className='
            mx-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md 
            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          '
          >
            Login
          </Link> */}

          <Link to='/telsignin' 
          className='
            mx-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md 
            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          '
          >
            TelSignIn
          </Link>
         

          {/*
          <button onClick={handleGoogleLogin} 
          className='
          mx-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md 
          text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none 
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          '
          >
            Google Sign In
          </button> */}

        </div>        
      )
    }



    </div>
  )
}

export default Navbar