
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import {
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";


const NewProduct = ({inputs, title}) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      console.log(name);
      const upsite = 'products/' + name
      const storageRef = ref(storage, upsite);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value })
  }

  const handleAdd = async(e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, "products", data['title']), {
        ...data,
        timeStamp: serverTimestamp()
      });
      navigate(-1)   
    } catch (err) {
      console.log(err);
    }

  }

  return (

    <div className="md:grid md:gap-6">

      <div className="mt-5 px-8 sm:px-0">
        <h1 className="px-8 text-2xl font-medium leading-6 text-gray-900">Add New Product</h1>
      </div>

      <form onSubmit={handleAdd} method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    id="title"             
                    type="text"
                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                    placeholder="new product"
                    onChange={handleInput}
                  />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  rows={3}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Nice Room"
                  defaultValue={''}
                  onChange={handleInput}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Brief description for your product
              </p>
            </div>

            <div>
              <label htmlFor='file'>
                Image: <DriveFolderUploadOutlinedIcon className='icon' />
              </label>
              <input 
                type='file'
                id='file'
                onChange={(e) => setFile(e.target.files[0])}
                style={{display:"none"}}
              />
            </div>
            <div className='mb-4'>
              <img
                className='max-w-sm h-auto rounded-lg'
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt="" 
              />
            </div>

          </div>
          <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
            <button
              disabled={per !== null && per < 100}
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>

  )
}

export default NewProduct