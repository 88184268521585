import React from 'react'
import { UserAuth } from '../context/AuthContext'
import { Navigate } from 'react-router-dom';

const Account = () => {
  const { user } = UserAuth();

  if (user) {

    return (
      <div className='
        border rounded-2xl shadow-xl px-2 mt-4
      '>
        <h1 className='text-2xl font-bold'>Account</h1>
        <div>
          <p> Welcome, {user?.email} : {user?.phoneNumber} </p>
        </div>
      </div>
    )    
  } else {
    return <Navigate to='/telsignin' />
  }

}

export default Account